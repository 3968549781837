import bugsnag from 'bugsnag-js';

// NOTE: テスト実行時の notifyReleaseStages 警告が邪魔だからログ無効化
const options = process.env.RAILS_ENV === 'test' ? { logger: null } : {};

const bugsnagClient = bugsnag({
  apiKey: '5f4f0346aad5b61abed383ed465ae41e',
  releaseStage: process.env.RAILS_ENV,
  notifyReleaseStages: ['staging', 'production'],
  ...options,
});

if (window.APP.rails.user) {
  bugsnagClient.user = window.APP.rails.user;
}
